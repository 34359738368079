import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Contact} from '@worxs/cms/types/contact';
import {environment} from '../../environments/environment';
import {SoMeLinks} from '@worxs/cms/types/some-links';
import {Header} from '@worxs/cms/types/header';

@Injectable({
  providedIn: 'root',
})
export class SingleTypesService {
  private contactPath = '/contact?populate=support_persons';
  private headerPath = '/header';
  private soMeLinksPath = '/some-link';

  constructor(private http: HttpClient) {

  }

  getContact(): Observable<Contact> {
    const url = `${environment.backendEndpoint + this.contactPath}`;
    return this.http.get<{ data: Contact }>(url).pipe(map(response => response.data));
  }

  getHeader(): Observable<Header> {
    const url = `${environment.backendEndpoint + this.headerPath}`;
    return this.http.get<{ data: Header }>(url).pipe(map(response => response.data));
  }

  getSoMeLinks(): Observable<SoMeLinks> {
    const url = environment.backendEndpoint + this.soMeLinksPath;

    return this.http.get<{ data: SoMeLinks }>(url)
      .pipe(map(response => response.data)
    );
  }
}
