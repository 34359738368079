<div class="share-container" *ngIf="SoMeLinks">
  <div [class]="background + ' d-flex justify-content-start align-items-center'">
    <ng-container *ngIf="!buttonsOnly">Del</ng-container>
    @if (SoMeLinks.attributes.instagram && !share) {
      <a class="share-button instagram" [href]="SoMeLinks.attributes.instagram" [attr.data-href]="url" target="_blank">
        <div style="background-image: url('assets/images/Icon awesome-instagram.svg')"></div>
      </a>
    }

    @if (SoMeLinks.attributes.facebook) {
      <a class="share-button facebook" [href]="share ? facebookShareLink() : SoMeLinks.attributes.facebook" [attr.data-href]="url" [target]="share ? 'pop' : '_blank'">
        <div style="background-image: url('assets/images/Icon awesome-facebook-f.svg')"></div>
      </a>
    }

    @if (SoMeLinks.attributes.linkedin) {
      <a class="share-button linkedin"  [href]="linkedInShareLink(article)" [attr.data-href]="url" target="_blank">
        <div style="background-image: url('assets/images/Icon awesome-linkedin-in.svg')"></div>
      </a>
    }

  </div>
</div>
